import { ErrorBoundary, ErrorPage, LoadingPage, Shell } from "@qubit/autoparts";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { AutostoreStatusInfo } from "~/components/debug/AutostoreStatusInfo";
import EventSimulator from "~/components/debug/EventSimulator";
import envConstants from "~/config/envConstants";
import { Navbar } from "~/features/navbar/Navbar";
import { NavigationDrawer } from "~/features/navigationDrawer/NavigationDrawer";
import { DevCheatsContext } from "~/hooks/useDevCheats";
import { AutostoreInactivityResetTimerProvider } from "~/hooks/useInactivityResetTimer";
import { useUnifyConnect } from "~/hooks/useUnifyConnect";
import { getStatusCodeFromError } from "~/lib/getStatusCodeFromError";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectInterceptorReady } from "~/redux/selectors/appSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { useAppSelector } from "./store";

/**
 * Renders Autostore pages.
 *
 * Fetches workstations and throws an error if fetching fails.
 * @constructor
 */
export function AutostoreLayout() {
  const { isPortPolling, showAutostoreStatus, showEventSimulator } =
    useContext(DevCheatsContext);

  const { t } = useTranslation();
  const [isAutostoreStatusOpen, setIsAutostoreStatusOpen] = useState(false);
  const [isEventSimulatorOpen, setIsEventSimulatorOpen] = useState(false);
  const userHasAnFc = !!useAppSelector(selectUsersFulfillmentCenter);
  const isInterceptorReady = useAppSelector(selectInterceptorReady);

  const { isLoading: isGetWorkstationsLoading, error: getWorkstationsError } =
    useGetWorkstationsQuery(undefined, {
      skip: !userHasAnFc || !isInterceptorReady
    });

  const devCheatClickHandler = useCallback(() => {
    if (envConstants.DEV_CHEATS_UAT !== "true") {
      return;
    }
    if (showAutostoreStatus) {
      setIsAutostoreStatusOpen(!isAutostoreStatusOpen);
    }
    if (showEventSimulator) {
      setIsEventSimulatorOpen(!isEventSimulatorOpen);
    }
  }, [
    isAutostoreStatusOpen,
    isEventSimulatorOpen,
    showAutostoreStatus,
    showEventSimulator
  ]);

  useUnifyConnect();

  return (
    <Shell
      AppBar={<Navbar onDevCheatClick={devCheatClickHandler} />}
      Drawer={<NavigationDrawer />}
    >
      <ErrorBoundary>
        {isGetWorkstationsLoading && (
          <LoadingPage loadingMessage={t("fetching workstations")} />
        )}
        {!!getWorkstationsError && (
          <ErrorPage
            errorMessage={getMessageFromRtkError(getWorkstationsError)}
            errorCode={getStatusCodeFromError(getWorkstationsError)}
          />
        )}
        {!isGetWorkstationsLoading && !getWorkstationsError && (
          <AutostoreInactivityResetTimerProvider>
            <Outlet />
          </AutostoreInactivityResetTimerProvider>
        )}
        {isAutostoreStatusOpen && (
          <AutostoreStatusInfo
            defaultAccordion="bin present"
            portPollingIsActive={isPortPolling}
            floating
          />
        )}
        {isEventSimulatorOpen && <EventSimulator floating />}
      </ErrorBoundary>
    </Shell>
  );
}
